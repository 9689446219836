<template>
  <div>
  
  </div>
</template>
<script>

export default {
    name: "LoginView",

  created() {
    window.location.href = "https://online.bdpcargo.co.id";
  },

};
</script>